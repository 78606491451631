import {format, distanceInWords, differenceInDays} from 'date-fns'
import React from 'react'
import {buildImageObj, cn} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import Container from './container'
import AuthorList from './author-list'

import styles from './blog-post.module.css'

function BlogPost (props) {
  const {
    _rawBody,
    authors,
    categories,
    title,
    mainImage,
    publishedAt,
    eventDate,
    subtitle,
    _rawPricing,
  } = props
  return (
    <article
      className={styles.root}
      style={{
          fontFamily: 'Montserrat',
          color: '#fff',
          backgroundColor: '#050504',
        }}
    >
      {mainImage && mainImage.asset && (
        <div className={styles.mainImage}>
          <img
            src={imageUrlFor(buildImageObj(mainImage))
              .width(1240)
              .fit('crop')
              .auto('format')
              .url()}
            alt={mainImage.alt}
          />
        </div>
      )}
      <Container>
        <h1 className="text-5xl text-bold tracking-wide">{title}</h1>
        <h2 className="text-xl tracking-wide">{subtitle}</h2>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>
          <aside className={cn(styles.metaContent, "border-l pl-4")}>
            {eventDate && (
              <div className="text-semibold text-2xl">
                  {format(new Date(eventDate), 'DD/MM/YYYY')}
              </div>
            )}
            {authors && <AuthorList items={authors} title='Authors' />}
            {_rawPricing && (
              <div className={styles.categories}>
                <h3 className="text-semibold text-2xl">Ceny:</h3>
                <ul>
                  {_rawPricing.map(price => (
                    <li key={price._id}>
                      <div>
                        <h3>{price.address}</h3>
                        <h3 className="text-semibold text-lg">{price.price}</h3>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </aside>
        </div>
      </Container>
    </article>
  )
}

export default BlogPost
